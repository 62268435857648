import {Component, OnInit} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  constructor(private router: Router) { }

  ngOnInit() {
    this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe((event: NavigationEnd) => {
      this.ocultarSegunURL(event.urlAfterRedirects);
    });
  }

  ocultarSegunURL(url: string) {
    if (url.includes('contacto')) {
      document.getElementById('content-contact').style.display = 'none';
    } else {
      document.getElementById('content-contact').style.display = 'block';
    }
  }

}
