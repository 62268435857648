<footer id="myfooter">



            <div  style="display:none;" class="footer-content  pt-5 pb-4">

                <div style="display:none;" class="container">
                    <div class="row">
                        <div class="footer-links col-sm-6 col-md-3 py-3 ">
                            <h3>bazar de turismo</h3>
                            <ul>
                                <li><a routerLink="quienes-somos">quienes somos</a></li>
                                <!--<li><a routerLink="/">prensa</a></li>-->
                            </ul>
                        </div><!-- /footer-links -->
                        <div class="footer-links col-sm-6 col-md-3 py-3">
                            <h3>soporte</h3>
                            <ul>
                                <li><a routerLink="faqs">preguntas frecuentes</a></li>
                                <li><a routerLink="contacto">contacto</a></li>
                            </ul>
                        </div><!-- /footer-links -->
                        <div class="footer-links col-sm-6 col-md-3 py-3">
                            <h3>políticas de uso</h3>
                            <ul>
                                <li><a routerLink="condiciones-generales">condiciones generales</a></li>
                                <li><a routerLink="aviso-legal">aviso legal</a></li>
                                <li><a routerLink="politicas-privacidad">políticas de privacidad</a></li>
                            </ul>
                        </div><!-- /footer-links -->
                        <div class="footer-links col-sm-6 col-md-3 py-3">
                            <h3>trabaja con nosotros</h3>
                            <ul>
                                <li><a target="_blank" href="https://beetest-123.web.app/#/auth/login">proveedor</a></li>
                                <li><a target="_blank" href="https://beetest-123.web.app/#/auth/login">agencias</a></li>
                                <li><a routerLink="trabaja-con-nosotros">empleo</a></li>
                            </ul>
                        </div><!-- /footer-links -->
                    </div><!-- /row -->
                </div><!-- /container -->    
            </div> 
          
        
<div style="position: fixed; bottom: 0px; width: 100%;">
            <div style="background: #f6f6f6;" id="content-contact" class="content pb-5 pt-5">
                <div class="section-home-contact container">
                  <div class="row">
                    <div class="col-md-8"> 
                      <p style="font-size: 22px;color: #009bdb;">nacimos para motorizar el cambio en la industria del turismo  </p>
                    </div>
                    <div class="col-md-4">
                      <button routerLink="contacto" class="button btn-blue ml-4">contáctanos!</button>   
                    </div>
                  </div> 
                </div>
              </div>

            <div  class="row-logos ml-0 mr-0 pt-4">
                <div class="container">
                    <div class="row">
                        <div class="col-md-9 pt-2 pb-4">
                            <a class="logo" routerLink="/"><img src="/assets/images/logos/logo.svg" alt="TravelAgency - Viajes y Turismo"/> </a>
                        </div>

                        <div class="col-md-3 pt-1 pb-3">
                            <div class="copyright text-right">© BazardeTurismo 2025</div> 
                        </div>

                      

                        <div style="display:none;" class="col-md-3 redes-sociales-footer">
                            <div  class="row">
                                <span class="col"><a title="Instagram" href="https://www.instagram.com/bazardeturismo/" target="_blank" class="social-links text-muted"><i class="lab la-instagram"></i></a></span>
                                <span class="col"><a title="Linkedin" href="https://www.linkedin.com/company/bazardeturismo/" target="_blank" class="social-links text-muted"><i class="lab la-linkedin"></i></a></span>
                                <span class="col"><a title="Facebook" href="https://www.facebook.com/Bazar-de-Turismo-104263015140528" target="_blank" class="social-links text-muted"><i class="lab la-facebook"></i></a></span>
                            </div>
                        </div>
                    </div>                        
                </div>
            </div>
       
        </div>
</footer>