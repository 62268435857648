<!-- HEADER START -->
<header id="header">
  <div class="header-wrapper bg-primary height-100-porciento">
    <div class="container height-100-porciento">
      <div class="row align-items-center text-muted navbar-custom-wrapper height-100-porciento container">
        <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4">
          <a routerLink="/" class="logo"><img src="/assets/images/logos/logo.svg" alt="bazar de turismo - Viajes y Turismo"></a>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-8 col-xl-8 navbar-custom row pr-0">
         <!-- <div routerLink="/pricing"  class="col-auto" ><a class="color-primary" title="Productos" data-toggle="tooltip" data-placement="left"><i class="las la-compress-arrows-alt"></i></a></div>
          <div routerLink="/quienes-somos"  class="col-auto" ><a title="Sobre Nosotros" data-toggle="tooltip" data-placement="left"><i class="las la-users"></i></a></div>
          <div routerLink="/partners"  class="col-auto" ><a title="Partners" data-toggle="tooltip" data-placement="left"> <i class="las la-handshake"></i></a></div>
        -->
          <div routerLink="/contacto"  class="col-auto" ><a title="contáctanos" data-toggle="tooltip" data-placement="left"><i class="las la-question-circle"></i></a></div>
          <div class="col-auto pr-0" ><a title="Accede al panel" data-toggle="tooltip" data-placement="left" target="_blank" href="https://app.bazardeturismo.com"><i class="las la-user-check"></i></a></div>
        </div>
      </div>
    </div>
  </div>
</header>
<!-- HEADER END -->
