<div class="container-home">
  <div class="row">
    <div style="padding-bottom: 117px;" class="col-sm-12">
        <div class="slider mb-5">
          <div class="content-slider container">
            <div class="content-slider-info">
              <h2>la nueva plataforma de soluciones adaptables
                a todos los negocios del turismo</h2>
              <p>
                potenciamos los resultados de tu empresa<br>
                presentando y mejorando tu oferta de manera<br>
                más atractiva, divertida y rápida
              </p>
              <!--<button  routerLink="pricing" class="button btn-blue">ver soluciones</button>
              <button  routerLink="quienes-somos" class="button btn-wihte ml-4">nuestra empresa</button>-->
            </div>
            <div class="content-slider-img">
              <img class="draw-images" style="width:560px;" src="/assets/images/settings-pana.svg" />
            </div>
           
          </div>
        </div> 
        <div style="background: #f6f6f6; display:none;" class="content pb-5 pt-5">
          <div class="section-home-contact container">
            <div class="row">
              <div class="col-md-8"> 
                <p style="font-size: 22px;">nacimos para motorizar el cambio en la industria del turismo  </p>
              </div>
              <div class="col-md-4">
                <button routerLink="contacto" class="button btn-blue ml-4">contáctanos!</button>   
              </div>
            </div> 
          </div>
        </div>
        
        <div style="display:none;" class="section-home-beneficios container mt-5 mb-5">
          <div class="title">
            <h2 style="margin-bottom: 18px;">un mercado global necesitaba una herramienta integral</h2>
          </div>
          <p style="text-align: center;">
            Tendrás la oportunidad de poder ofrecer tu propio inventario e inventarios complementarios a los tuyos a través de la conexión transparente con proveedores externos. De esta manera puedes ofrecer a tus clientes una variedad de servicios y paquetes dinámicos que hasta ahora eran imposibles de gestionar desde una sola Web.<br>
            De este modo, tu compañía podrá ofrecer simultáneamente tus propios productos y/o los de proveedores externos, dando a tus clientes la opción de escoger entre una amplia gama de productos en tu Web como valor añadido.
          </p>
          <div class="row mt-5">
            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">

              <div class="select-option" role="button">
                <div class="number-item" (click)="tabToggle(1)">01</div>
                <div class="title-item" [ngClass]="{'color-principal': showTab == 1}" (click)="tabToggle(1)" >Control total Administrativo <div class="icon-item"><i *ngIf="showTab == 1" class="las la-angle-up"></i><i *ngIf="showTab != 1" class="las la-angle-down"></i></div></div>
                <div *ngIf="showTab == 1" class="text-item">El más robusto y completo ERP 100% online. Versátil, usable, una herramienta innovadora y con todas las herramientas que tu compañía necesita para tener un control administrativo eficiente y confiable</div>
              </div>
              <div class="select-option" role="button">
                <div class="number-item" (click)="tabToggle(2)">02</div>
                <div class="title-item"  [ngClass]="{'color-principal': showTab == 2}"  (click)="tabToggle(2)" >Una experiencia de usuario diferente <div class="icon-item"><i *ngIf="showTab == 2" class="las la-angle-up"></i><i *ngIf="showTab != 2" class="las la-angle-down"></i></div></div>
                <div *ngIf="showTab == 2" class="text-item">La interfaz ha sido diseñada para cambiar la complejidad en la cotización, la paquetización y la creación de itinerarios de las ventas on-line en una experiencia sencilla y que permite ahorrar tiempo.</div>
              </div>
              <div class="select-option" role="button">
                <div class="number-item" (click)="tabToggle(3)">03</div>
                <div class="title-item" [ngClass]="{'color-principal': showTab == 3}"  (click)="tabToggle(3)" >Mucho más que B2B y B2C <div class="icon-item"><i *ngIf="showTab == 3" class="las la-angle-up"></i><i *ngIf="showTab != 3" class="las la-angle-down"></i></div></div>
                <div *ngIf="showTab == 3" class="text-item">Llegamos para generar un cambio, abrir mercados y democratizar la industria de los viajes. Tienda de Turismo es nuevo concepto que permite llegar de forma más eficiente al viajero.</div>
              </div>
              <div class="select-option" role="button">
                <div class="number-item" (click)="tabToggle(4)">04</div>
                <div class="title-item" [ngClass]="{'color-principal': showTab == 4}"  (click)="tabToggle(4)" >Personaliza tu portal web<div class="icon-item"><i *ngIf="showTab == 4" class="las la-angle-up"></i><i *ngIf="showTab != 4" class="las la-angle-down"></i></div></div>
                <div *ngIf="showTab == 4" class="text-item">Podrás añadir los datos de tu agencia: logo, dirección, horarios de apertura, redes sociales y mucho más control. Tu agencia. Tu portal. Tu estrategia comercial.</div>
              </div>
              <div class="select-option" role="button">
                <div class="number-item" (click)="tabToggle(5)">05</div>
                <div class="title-item" [ngClass]="{'color-principal': showTab == 5}"  (click)="tabToggle(5)" >Uso de dominio propio<div class="icon-item"><i *ngIf="showTab == 5" class="las la-angle-up"></i><i *ngIf="showTab != 5" class="las la-angle-down"></i></div></div>
                <div *ngIf="showTab == 5" class="text-item">Si ya posees un dominio propio, configúralo para gestionar tu web. Ella te dará la posibilidad de una web actualizada 365 días del año sin invertir en tecnología.</div>
              </div>
              <div class="select-option" role="button">
                <div class="number-item" (click)="tabToggle(6)">06</div>
                <div class="title-item" [ngClass]="{'color-principal': showTab == 6}" (click)="tabToggle(6)" >Incluye contratos con tus proveedores vía XML <div class="icon-item"><i *ngIf="showTab == 6" class="las la-angle-up"></i><i *ngIf="showTab != 6" class="las la-angle-down"></i></div></div>
                <div *ngIf="showTab == 6" class="text-item">Múltiples proveedores de transporte, alojamiento, servicios terrestres, circuitos y paquetes integrados. Un sistema de reservas muy flexible y con variedad de opciones.</div>
              </div>
            </div>  



            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
             
              <div class="select-option" role="button">
                <div class="number-item" (click)="tabToggle(7)">07</div>
                <div class="title-item" [ngClass]="{'color-principal': showTab == 7}"  (click)="tabToggle(7)" >Carga de productos y servicios <div class="icon-item"><i *ngIf="showTab == 7" class="las la-angle-up"></i><i *ngIf="showTab != 7" class="las la-angle-down"></i></div></div>
                <div *ngIf="showTab == 7" class="text-item">Nuestra plataforma permite cargar producto propio desde vuelos, paquetes, actividades, hoteles y servicios ¡todo lo que sea necesario! y 100% disponible para la red de clientes.</div>
              </div>
              <div class="select-option" role="button">
                <div class="number-item" (click)="tabToggle(8)">08</div>
                <div class="title-item" [ngClass]="{'color-principal': showTab == 8}"  (click)="tabToggle(8)" >100% integrado con Tienda de Turismo <div class="icon-item"><i *ngIf="showTab == 8" class="las la-angle-up"></i><i *ngIf="showTab != 8" class="las la-angle-down"></i></div></div>
                <div *ngIf="showTab == 8" class="text-item">Además de tu sitio web de reservas incluído en la licencia, podrás formar parte de Tienda de Turismo, nuestro Marketplace donde podrás conectar miles de productos con confirmación online. Productos dinámicos en tu portal web con una amplia conectividad y el set de herramientas más versátil.</div>
              </div>

              <div class="select-option" role="button">
                <div class="number-item" (click)="tabToggle(9)">09</div>
                <div class="title-item" [ngClass]="{'color-principal': showTab == 9}"  (click)="tabToggle(9)" >Marca tu propia rentabilidad <div class="icon-item"><i *ngIf="showTab == 9" class="las la-angle-up"></i><i *ngIf="showTab != 9" class="las la-angle-down"></i></div></div>
                <div *ngIf="showTab == 9" class="text-item">Configura tus reglas de negocios para hoteles, paquetes, circuitos y mucho más.</div>
              </div>

              <div class="select-option" role="button">
                <div class="number-item" (click)="tabToggle(10)">10</div>
                <div class="title-item" [ngClass]="{'color-principal': showTab == 10}"  (click)="tabToggle(10)" >Creación de múltiples roles de usuario <div class="icon-item"><i *ngIf="showTab == 10" class="las la-angle-up"></i><i *ngIf="showTab != 10" class="las la-angle-down"></i></div></div>
                <div *ngIf="showTab == 10" class="text-item">Asignación de roles de manera intuitiva que permite vincular las herramientas acordes a las funciones específicas.</div>
              </div>

              <div class="select-option" role="button">
                <div class="number-item" (click)="tabToggle(11)">11</div>
                <div class="title-item" [ngClass]="{'color-principal': showTab == 11}"  (click)="tabToggle(11)" >Instalación de Widgets (En desarrollo) <div class="icon-item"><i *ngIf="showTab == 11" class="las la-angle-up"></i><i *ngIf="showTab != 11" class="las la-angle-down"></i></div></div>
                <div *ngIf="showTab == 11" class="text-item">Instalar un chat online o un sistema de notificaciones autónomo para interactuar con tus usuarios online. Plataforma de pagos online.</div>
              </div>

              <div class="select-option" role="button">
                
                <div class="title-item ml-0" [ngClass]="{'color-principal': showTab == 12}"  (click)="tabToggle(12)" >Y mucho más...  <div class="icon-item"></div></div>
                <div *ngIf="showTab == 12" class="text-item"></div>
              </div>
            </div>
          </div>
        </div> 
        <div style="display:none;" class="section-home-motores mt-5">
          <div class="container">
            <div class="row pt-6 pb-5">
              <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4"> 
                <h2>Completo booking engine versátil, usable y que permite optimizar las ventas</h2>
                <img class="icon-big  mt-5 mb-5" src="/assets/images/iconos/svg/computer_icon.svg" />
                <div class="content-frase">
                  <div class="icono-frase"> 
                      <img src="/assets/images/iconos/svg/quotation_mark.svg" />
                  </div>          
                  <div class="texto-frase">
                    Evolucionamos nuestras herramientas permitiendo versatilidad al mejor costo del mercado.
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4"> 
                <div class="content-detalle">
                  <div class="icono-detalle"> 
                      <img src="/assets/images/iconos/svg/alojamientos.svg" />
                  </div>          
                  <div class="texto-detalle">
                      <h5>Alojamientos</h5>
                      <p>
                        El motor de reservas con la mejor tecnología y múltiples integraciones. Una herramienta completa y robusta, pero también flexible. Permitiendo tener control total a través de reglas de compra y venta.
                      </p>
                  </div>
                </div>

                <div class="content-detalle">
                  <div class="icono-detalle"> 
                      <img src="/assets/images/iconos/svg/vuelo-hotel.svg" />
                  </div>          
                  <div class="texto-detalle">
                      <h5>Vuelos</h5>
                      <p>
                        Enfócate en lo que importa a su negocio, nos ocupamos del resto. Tarifas negociadas. Aplica comisiones y mark ups para potenciar las ventas, a través de nuestro potente módulo de reglas.
                      </p>
                  </div>
                </div>

                <div class="content-detalle">
                  <div class="icono-detalle"> 
                      <img src="/assets/images/iconos/svg/traslados.svg" />
                  </div>          
                  <div class="texto-detalle">
                      <h5>Traslados</h5>
                      <p>
                        La forma más fácil de reservar transfers en miles de destinos, encuentra todo lo que necesitas, desde traslados compartidos, privados, ejecutivos y de lujo.<br>
                      </p>
                  </div>
                </div>

                <div class="content-detalle">
                  <div class="icono-detalle"> 
                      <img src="/assets/images/iconos/svg/actividades.svg" />
                  </div>          
                  <div class="texto-detalle">
                      <h5>Actividades</h5>
                      <p>
                        Todo tipo de tours, excursiones, eventos y actividades deportivas, teatros, conciertos, restaurantes, y mucho más. Los mejores proveedores y prestadores locales en un único marketplace.
                      </p>
                  </div>
                </div>

                <div class="content-detalle">
                  <div class="icono-detalle"> 
                      <img src="/assets/images/iconos/svg/asistencia.svg" />
                  </div>          
                  <div class="texto-detalle">
                      <h5>Asistencia</h5>
                      <p>
                        Añade valor agregado a sus servicios. En este tiempo resulta un producto fundamental y exigible por gran cantidad de países. Completa tu oferta de productos con disponibilidad en tiempo real.  
                      </p>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4"> 

                <div class="content-detalle">
                  <div class="icono-detalle"> 
                      <img src="/assets/images/iconos/svg/rent-a-car.svg" />
                  </div>          
                  <div class="texto-detalle">
                      <h5>Autos</h5>
                      <p>
                        Alquileres alrededor del mundo con seguros y los extras para cada tipo de viaje. Todos los servicios necesarios para concretarlo, en una navegación simple e intuitiva que le permite combinar el servicio con vuelos y hoteles online 100%.
                      </p>
                  </div>

                <div class="content-detalle">
                  <div class="icono-detalle"> 
                      <img src="/assets/images/iconos/svg/multidestino.svg" />
                  </div>          
                  <div class="texto-detalle">
                      <h5>Circuitos</h5>
                      <p>
                        Los mejores proveedores del mercado integrados en una única plataforma, diseñada para ofrecer una navegación intuitiva. Itinerario detallado, fotos, hoteles previstos, documentación y políticas de cancelación.
                      </p>
                  </div>
                </div>

                <div class="content-detalle">
                  <div class="icono-detalle"> 
                      <img src="/assets/images/iconos/svg/multipack.svg" />
                  </div>          
                  <div class="texto-detalle">
                      <h5>Paquetes</h5>
                      <p>
                        Manejo de inventario con el mejor sistema de armado de paquetes del mercado. Ya sea en bus o avión permite administrar y disponibilizar cupos online. Carga propia o través de nuestra integración exclusiva.
                      </p>
                  </div>
                </div>
                <div class="content-detalle">
                  <img class="draw-images" src="/assets/images/draw_6.svg" />
                </div>
                
               
                </div>
              </div>
            </div> 
          </div>  
        </div>

        <div style="display:none;" class="section-home-prestaciones  pt-5 mb-5">
          <div class="container">
          <div class="title mb-5">
            <h4>modelo de negocios para todas las verticals de turismo</h4>
            <h2>one for all solution</h2>
            
          </div>  


          <div class="row">
            <div class="col-md-12">
                <p>
                    Nuestras soluciones son versátiles y adaptables a todos los negocios y segmentos del ámbito turístico. Una oferta integral donde puedes ser agregar o creador de contenido distribuido en nuestro amplio Marketplace.
                </p>
                <p>
                    Desde un experto freelance hasta una nueva vertical en tu negocio de retail. Tenemos una solución integral, intuitive, integrable, versatile y en constante evolución.<br><br><br><br>
                </p>
            </div>
          </div>  

          <div class="row iconos">
            <div class="col-md-15">
                <img src="/assets/images/prestaciones/freelance.png" />
                <h4>Idóneos<br><br></h4>
                <p><b>Apunta todos los canales!</b><br>
                  Podés vender 24/7 todos productos sin límites. Una nueva fuente de ingresos para recuperar la economía. Fácil, ágil e intuitivo. Tu negocio ya.  
                  </p>
            </div>
            <div class="col-md-15">
              <img src="/assets/images/prestaciones/one-man-show.png" />
              <h4> One Man<br> Show</h4>
              <p><b>Simple, robusto y ágil.</b><br>
                Promocionar, Vender y Administrar nunca fue tan sencillo. Tecnología que facilita la tarea diaria.</p>
            </div>
            <div class="col-md-15">
                <img src="/assets/images/prestaciones/red-de-agencias.png" />
                <h4>Red de <br>Agencias</h4>
                <p><b>La herramienta tecnológica one desk</b><br>
                  Una solución integral que permite a cada usuario la personalización del producto ofrecido y una administración eficiente.</p>
            </div>
            <div class="col-md-15">
                <img src="/assets/images/prestaciones/franquicias.png" />
                <h4>Franquicias<br><br></h4>
                <p><b>Una red propia bajo un modelo eficiente</b><br>
                  Una herramienta eficiente, tecnológica, de bajo costo y con una gran variedad de productos. Multi-sucursales.</p>
            </div>
            <div class="col-md-15">
              <img src="/assets/images/prestaciones/tour-operators.png" />
              <h4>Tour Operators<br><br></h4>
              <p><b>Presencia Omnicanal</b><br>
                Producto propio, conectores XML, agregador de contenidos y el armado de una red de agencias.
              </p>
          </div>
         
          <div class="col-md-15">
            <img src="/assets/images/prestaciones/dmc.png" />
            <h4>DMC<br><br></h4>
            <p><b>Un proveedor Digital</b><br>
              Alimentar el marketplace, la red de proveedores y tu propio sitio web, todo en un one desk solution.
            </p>
          </div>

          <div class="col-md-15">
              <img src="/assets/images/prestaciones/hoteles.png" />
              <h4>Hoteles<br><br></h4>
              <p><b>Amplia tu oferta facilitando experiencias </b><br>
                Un nuevo modelo de negocios con tecnología ágil y disponible 24/7 para todos los huépedes.</p>
          </div>
          <div class="col-md-15">
              <img src="/assets/images/prestaciones/prestadores-terrestres.png" />
              <h4>Prestadores<br>Terrestres</h4>
              <p><b>Complementar tu oferta en destino</b><br>
                Ofrecer servicios adicionales de forma digital bajo una misma plataforma. Forma parte de nuestro marketplace. </p>
          </div>
          <div class="col-md-15">
            <img src="/assets/images/prestaciones/gobiernos.png" />
            <h4>Gobiernos<br><br></h4>
            <p><b>Una red de prestadores y proveedores locales</b><br>
              Restablecer el turismo como fuente de ingreso y generación de empleos. Un proyecto social para la recuperación.</p>
        </div>
        <div class="col-md-15">
          <img src="/assets/images/prestaciones/empresas-de-retail.png" />
          <h4>Empresas de retail<br><br></h4>
          <p><b>Genera recursos adicionales con tu red de clientes </b><br>
            Crea una nueva vertical del negocio aumentando la facturación y beneficios sin inversión adicional.   </p>
        </div>
          </div>  
      </div>
    </div>

        <div style="display:none;" class="section-home-sistemas-reservas container mt-5 mb-5">
          <div class="title mb-5">
            <h2>nuestra tecnología de omnicanalidad </h2>
            <h4>diferentes maneras de implementación</h4>
          </div>  

          <div class="row">
            <div class="col-md-3">
              <b>Tu empresa dispondrá de la una completa suite de aplicaciones online,</b> control administrativo y contable, información dinámica sobre las ventas de tus productos y gestión de reservas, gestión de los contenidos de tu Web. <b>El producto con la mejor relación costo / beneficio del mercado.</b>
            </div>
            <div class="col-md-3">
              <b>Gestión total de productos propios y de terceros. Reglas de distribución</b> flexibles, eficientes y completas que permiten priorizar el producto por proveedores y por booking engine, rutas, orígenes, destinos, ciudades y mucho más. <b>Sumar comisiones y markups en diferentes escalas, tantos como necesites 100% online.</b>
            </div>
            <div class="col-md-3">
              Hoy en los mercados prima la inmediatez, la flexibilidad de tarifas y la tecnología que permite realizar esto es costosa y sofisticada. <b>Bazar de Turismo ofrece el equilibrio entre las necesidades de nuestros socios, ampliar su red comercial de manera sencilla y oportuna pero costeable.</b>
            </div>
            <div class="col-md-3">
              <b>Un motor de reservas ágil, práctico e intuitivo para dar el salto tecnológico.</b> Nuestro sistema modular diseñado como un servicio integral para expandir tu negocio y aumentar ventas. Cuenta con diferentes módulos como aéreos, alojamientos, servicios y traslados, alquiler de autos, circuitos y paquetes.
            </div>
          </div>  
      </div>
                                                                   
   

        <div style="display:none;" class="section-home-productos-contratos container mt-5 mb-5">
          <div class="title mb-5">
            <h2>suma tus productos y contratos</h2>
          </div>  
          
          <div class="row">
            <div class="col-xs-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 my-3 text-center">
              <img src="/assets/images/iconos/svg/transportes_color.svg" />
              <h5>transportes</h5>
            </div>

            <div class="col-xs-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 my-3 text-center">
              <img src="/assets/images/iconos/svg/traslados_color.svg" />
              <h5>traslados</h5>
            </div>

            <div class="col-xs-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 my-3 text-center">
              <img src="/assets/images/iconos/svg/tours_color.svg" />
              <h5>tours</h5>
            </div>

            <div class="col-xs-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 my-3 text-center">
              <img src="/assets/images/iconos/svg/rent-a-car_color.svg" />
              <h5>rent a car</h5>
            </div>

            <div class="col-xs-6 col-sm-12 col-md-4 col-lg-2 col-xl-2 my-3 text-center">
              <img src="/assets/images/iconos/svg/actividades_color.svg" />
              <h5>actividades</h5>
            </div>

            <div class="col-xs-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 my-3 text-center">
              <img src="/assets/images/iconos/svg/hoteleria_color.svg" />
              <h5>alojamiento</h5>
            </div>
          </div> 

          <div class="row d-flex justify-content-center text-center mt-5">
            <div class="col-4">
              <button class="button btn-blue full-width mr-4 my-2" routerlink="/" >registro proveedor de servicios</button>
            </div>
            <div class="col-4">
              <button class="button btn-blue full-width my-2" routerlink="/" >registro proveedor de alojamiento</button>
            </div>
          </div>

           <!--  <div class="row mt-5">
              <div class="col-md-2 pl-0 pr-0"></div>
              <div class="col-md-4"><button class="button btn-blue ml-4 full-width" routerlink="/" >registro proveedor de servicios</button></div>
              <div class="col-md-4"><button class="button btn-blue ml-4 full-width" routerlink="/" >registro proveedor de alojamiento</button></div>
          </div> -->
      </div>
     
      <div style="display:none;" class="section-home-cobertura-total mt-5 mb-5 pt-5 pb-5">
        <div class="container">
          <div class="title mb-5">
            <h2>cobertura total: genera nuevas oportunidades de negocio</h2>
            <h4>multiplica tus canales de distribución mediante nuestro novedoso marketplace. genera nuevas oportunidades de negocio.</h4>
          </div>  
          <div class="row ">
            <div class="col-md-5">
              <img src="/assets/images/cuatro_circulos.png" />
            </div>
            <div class="col-md-1">

            </div>
            <div class="col-md-6">
              <div class="content-caracteristica mb-5">
                <div class="icono-caracteristica"> 
                    <img src="/assets/images/iconos/svg/cms_completo_color.svg" />
                </div>          
                <div class="texto-caracteristica">
                    <h5>Site Builder (CMS).<br>Booking Engine (Reglas comerciales y control de operaciones).<br> ERP (administra tu compañía).</h5>
                </div>
              </div>
              <div class="content-caracteristica">
                <div class="icono-caracteristica"> 
                    <img src="/assets/images/iconos/svg/informes_completo_color.svg" />
                </div>          
                <div class="texto-caracteristica">
                    <h5>Informes, análisis e integración. La puerta de acceso a una red de compradores y vendedores.  </h5>
                </div>
              </div>
            </div>
            
            
          </div> 
        </div>
      </div>

      
        
    </div>
  </div>
</div>

<div style="display:none;" class="section-home-newsletter container mb-6">
  <div class="title pt-5 mt-5 mb-5">
    <h2>contáctate con nosotros por más información</h2>
  </div>  
  <div class="row">
    <div class="col-md-4"> 
      <form (ngSubmit)="onSubmit()" #formNews="ngForm" [formGroup]="news">
        <input class="input" type="text" formControlName="email" name="email"  [ngClass]="{'aviso-input': faltaEmail > 0 }" placeholder="ingresa tu e-mail">
        <button class="button btn-blue ml-4 suscribite">{{button_text}}</button>   
      </form>
       <div [ngClass]="{'aviso-text': faltanCampos > 0 }" class="col-sm-6 pt-4 pl-3 position-absolute-25bt hidden">
           debe ingresar su e-mail
       </div>
     </div>
    <div class="col-md-4">
      <button routerLink="/pricing" class="button btn-blue ml-4 full-width mb-2">ver soluciones</button>   
    </div>
    <div class="col-md-4">
      <button routerLink="/contacto" class="button btn-blue ml-4 full-width">contáctanos</button>   
    </div>
  </div> 
</div>

<p-dialog header="Titulo" [(visible)]="displayBasic" [style]="{width: '50vw'}" [baseZIndex]="10000">
  Muchas gracias por su interés en nuestra compañía, lo mantendremos informado de todas las novedades.
  <p-footer>
    <button type="button" pButton icon="pi pi-check" (click)="displayBasic=false" label="Yes">OK</button>
  </p-footer>
</p-dialog>
